<template>
  <footer>
    <div class="tuev-trustpilot-wrapper">
      <TuevSeal v-if="!blok.isTuevFooterHidden" :copy="true" />
      <TrustPilot v-if="!blok.isTrustpilotFooterHidden" />
    </div>
    <nav aria-label="Fußzeilen-Navigation">
      <ul>
        <li v-for="link in links" :key="link.index">
          <a :href="link.href" :title="link.title" target="_blank">{{ link.label }}</a>
        </li>
      </ul>
      <ul>
        <li>
          <NuxtLink
            to="https://www.pflege.de/agb"
            target="_blank"
            title="Die allgemeinen Geschäftsbedingungen von pflege.de in neuem Tab öffnen ⇱"
            external
          >
            AGB
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            to="https://www.pflege.de/impressum"
            target="_blank"
            title="Das Impressum von pflege.de in neuem Tab öffnen ⇱"
            external
          >
            Impressum
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            to="https://www.pflege.de/datenschutz"
            target="_blank"
            title="Die Datenschutzerklärung von pflege.de in neuem Tab öffnen ⇱"
            external
          >
            Datenschutz
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: false,
    default: () => {
      return {
        isTuevFooterHidden: false,
        isTrustpilotFooterHidden: true,
        footerLinks: []
      }
    }
  }
})

const links = reactive([])

const handelFooterLinks = (linksArray) => {
  if (linksArray) {
    // eslint-disable-next-line complexity
    linksArray.forEach((link) => {
      switch (link) {
        case 'https://www.pflege.de/faq':
          links.push({
            href: 'https://www.pflege.de/faq',
            title: 'Die häufigsten Fragen und deren Antworten von pflege.de in neuem Tab öffnen ⇱',
            label: 'FAQ'
          })
          break
        case '#!Hilfe-Kontakt-Inko':
          links.push({
            href: 'https://www.curabox.de/inko/kontakt',
            title: 'Hilfe und Kontaktmöglichkeiten zum Produkt InKo in neuem Tab öffnen ⇱',
            label: 'Hilfe & Kontakt'
          })
          break
        case '#!Hilfe-Kontakt-Pflege':
          links.push({
            href: 'https://www.curabox.de/pflege/kontakt',
            title: 'Hilfe und Kontaktmöglichkeiten zum Produkt Pflege in neuem Tab öffnen ⇱',
            label: 'Hilfe & Kontakt'
          })
          break
        case '#!Pflegehilfsmittel':
          links.push({
            href: '#!',
            title:
              'Eine Übersicht der verfügbaren Pflegehilfsmittel des InKo in neuem Tab öffnen ⇱',
            label: 'Pflegehilfsmittel'
          })
          break
        case '#!Inkontinenzverwaltung':
          links.push({
            href: '#!',
            title: 'Inkontinenzverwaltung einfach erklärt - in neuem Tab öffnen ⇱',
            label: 'Inkontinenzverwaltung'
          })
          break
        case 'https://www.pflege.de/ueber-pflege-de/':
          links.push({
            href: 'https://www.pflege.de/ueber-pflege-de/',
            title: 'Wir stellen uns vor - das Profil von pflege.de in neuem Tab öffnen ⇱',
            label: 'Über pflege.de'
          })
          break
        case '#!HWG-PFLICHTTEXTE':
          links.push({
            href: 'https://www.pflege.de/hwg-pflichttexte',
            title:
              'Pflichtangaben gemäß dem Heilmittelwerbegesetz (HWG) und Biozidrecht in neuem Tab öffnen ⇱',
            label: 'HWG-Pflichttexte'
          })
          break
        case '#!CB-FAQ':
          links.push({
            href: 'https://www.curabox.de/faq',
            title: 'Die häufigsten Fragen und deren Antworten von curabox.de in neuem Tab öffnen ⇱',
            label: 'FAQ'
          })
          break
      }
    })
  }

  return links
}

onMounted(() => {
  handelFooterLinks(props.blok?.footerLinks)
})

watch(
  () => props.blok?.footerLinks,
  (newValue) => {
    links.length = 0
    handelFooterLinks(newValue)
  }
)
</script>

<style scoped>
footer {
  background-color: var(--mint-light);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1.75rem;
  padding-block: 1.25rem;
}

footer .tuev-trustpilot-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

@media (min-width: 576px) {
  .tuev-trustpilot-wrapper {
    align-items: start;
  }
}

footer .tuev-trustpilot-wrapper > * {
  padding: 0.5rem;
}

footer nav ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin: 0;
  padding: 1.25rem;
}

footer nav ul a {
  color: currentColor;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
}

footer nav ul a:is(:hover, :active) {
  text-decoration: underline;
  color: currentColor;
}

@media (max-width: 576px) {
  .tuev-trustpilot-wrapper {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  footer nav ul:nth-of-type(1) {
    margin-bottom: 1.25rem;
  }
}
</style>
